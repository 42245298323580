import React, { useRef, useState,useEffect } from 'react';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import { getAllUsers } from 'api/users';

import {
  switchUserByAdmin
} from 'auth/authRequests';

import placeholderUser from 'assets/icons/placeholder-user.svg';
import styled from '@emotion/styled';
import PopMenu from 'components/PopMenu';
import useAuthContext from 'hooks/useAuthContext';
import { Button, Icon } from '@xchange/uikit';
import { UserViewType } from 'enums';

const HeaderAvatarMenu = props => {
  const { authorized, logout, profile, authData, changeViewType ,setAuthData,setProfile } = useAuthContext();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const userPhoto = profile?.avatar || placeholderUser;
  const isAdmin = authData?.admin;
  const [users, setAllUsers] = useState([]);

  const handleMenuButtonClick = (callback, params?: any) => {
    setOpen(false);
    callback(params);
  };

  const switchUserById = async (user_id: number) => {
    var data = await switchUserByAdmin(user_id);
    setAuthData({ ...data.authData, admin: data.admin, teamLead: data.teamLead, superAdmin: data.superAdmin });
    setProfile(data.profile);
    window.location.reload(false);
  };

  const handlePopMenuOutsideClick = e => {
    /*if (open && !(buttonRef.current as any).contains(e.target))*/ setOpen(false);
  };

  if (!authorized) return (
    <span></span>
  );

  useEffect(() => {
    if (!authData.superAdmin && !open) return;
    (async () => {
      var tempusers = await getAllUsers();
      setAllUsers(tempusers);
    })();
  }, [open]);

  

  return (
    <>
      <StyledAvatarButton
        ref={buttonRef}
        className={clsx('avatar-button', { open })}
        onClick={() => setOpen(v => !v)}
        {...props}>
        <img className="user-photo" src={userPhoto} alt="avatar" data-cy="avatar" />{' '}
        <Icon name="chevron" />
      </StyledAvatarButton>
      <StyledPopMenu
        open={open}
        target={buttonRef.current}
        placement="bottom-end"
        onOutsideClick={handlePopMenuOutsideClick}>
        {isAdmin && (
          <>
            <Button
              simple
              className={clsx({ active: !profile?.onlyMeView })}
              onClick={() => handleMenuButtonClick(changeViewType, UserViewType.ADMIN)}
            >
              <Icon name="user-outline" /> Admin
            </Button>
            <Button
              simple
              className={clsx({ active: profile?.onlyMeView })}
              onClick={() => handleMenuButtonClick(changeViewType, UserViewType.ME)}
            >
              <Icon name="user-outline" /> Me
            </Button>
            <hr />
          </>
        )}
        {authData.superAdmin && users.length > 0 && (
          <>
          <div className='divForExtraUsers'>
            <p className='switchIntUser'>Switch Internal User</p>
          {users.map((user, idx) => (
              <Button
              style={{ textAlign: 'left'}}
              key={"user_header_"+user.id}
              simple
              onClick={() => switchUserById(user.id)}
            >
              &#8226; {user.name}
            </Button>
            ))}
          </div>
            
            <hr />
          </>
        )}
        <Button simple onClick={() => handleMenuButtonClick(logout)}>
          <Icon name="logout" /> Logout
        </Button>
      </StyledPopMenu>
    </>
  );
};

export default HeaderAvatarMenu;

const StyledAvatarButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  .user-photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.lightGray};
    cursor: pointer;
    flex-shrink: 0;
  }

  .icon-chevron {
    margin-left: 4px;
  }

  &.open .icon-chevron {
    transform: rotate(180deg);
  }
`;

const StyledPopMenu = styled(PopMenu)`
  background: white;
  border: ${props => props.theme.colors.borderColor};
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px #ccc;
  padding: 10px;
  z-index: 1010;
  width: 164px;

  .divForExtraUsers{
    max-height: 300px;
    overflow: auto;
  }

  .switchIntUser{    margin-top: 0;
    font-weight: bold;}

  button.simple {
    display: block;
    margin: 8px 0;
    background: none;
    border: none;
    font-weight: normal;
    .icon {
      margin-right: 10px;
      fill: ${props => props.theme.colors.grayDark};
    }
    &.active, &:hover {
      font-weight: 500;
      .icon {
        fill: ${props => props.theme.colors.red};
      }
    }
  }

   hr {
     display: block;
     margin: 16px 0;
     border-color: #c4c4c4;
   }
`;
