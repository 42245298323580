import notify from 'notify';

export const camelToNormal = s => {
  if (!s) return '';
  const result = s.replace(/([A-Z])/g, ' $1');
  const firstChar = result.charAt(0).trim() ? result.charAt(0).toUpperCase() : '';
  const finalResult = firstChar + result.slice(1);
  return finalResult;
};

export const copyToClipboard = event => {
  //console.log(event);
  var target = event.target;
  var copyText = target.innerText;

  navigator.clipboard.writeText(copyText);

  notify("Copied to clipboard");
};